import videoBG from '../video.mp4'

import '../css/Header.css'

const Header = () => {
    return (
        <div id="header">
            <video src={videoBG} autoPlay loop muted playsInline />
            <h1 id="hdrTitle"><span>Glob Gang Productions</span></h1>
        </div>
    );
}
 
export default Header;