import { useState, useRef } from 'react'

import { artist } from './ArtistBio'
import InfoContainer from './InfoContainer'

import arrow from '../pictures/arrow.svg'

import '../css/Body.css'

const Body = () => {
    const [ index, setIndex ] = useState(0);
    const ref = useRef(null);

    function onClickForward() {
        if(index + 1 === artist.length) {
            setIndex(0);
        } else {
            setIndex(index + 1);
        }
        ref.current = 1;
    };

    function onClickBack() {
        if(index == 0) {
            setIndex(artist.length - 1);
        } else {
            setIndex(index - 1);
        }
        ref.current = 0;
    };

    return (
        <div id="gradient-main">
            <div id="main">
                <div className="btn-nav" id="back" onClick={onClickBack}>
                    <img src={arrow} className="arrow" />
                </div>
                <InfoContainer name={artist[index].name} img={artist[index].img} sc={artist[index].sc} insta={artist[index].insta} x={artist[index].x} youtube={artist[index].youtube} tiktok={artist[index].tiktok} text={artist[index].text} clickedArrow={ref.current} index={index}/>
                <div className="btn-nav" id="forward" onClick={onClickForward}>
                    <img src={arrow} className="arrow" />
                </div>  
            </div>
        </div>
    );
}
 
export default Body;